@import "../../assets/stylesheets/variables";

.container {
  width: 100%;
  margin-bottom: 7px;

  .statusRow {
    display: flex;
    align-items: center;

    .refreshBtn {
      margin-left: 7px;
      cursor: pointer;
    }

    .circle {
      height: 18px;
      width: 18px;
      border-radius: 50%;
      display: inline-block;
      &.success {
        background-color: green;
      }
      &.error {
        background-color: $selected_red;
      }
    }

    .status {
      padding-left: 12px;
    }
  }

  .link {
    padding-top: 5px;
    text-align: end;
  }

  .downloadRow {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .label {}

    .downloadButton {
      display: flex;
      justify-content: space-between;
      align-items: center;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      border-radius: 4px;
      border: solid 1px $blue_1;
      box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4), 0 1px 1px rgba(0, 0, 0, 0.2);
      background-color: $blue_3;
      color: #FFF;
      padding: 4px;
      text-decoration: none;
      &Icon {
        margin-left: 5px;
      }
    }
  }
}
